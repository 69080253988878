import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header/Header';
import SideBar from './components/SideBar/SideBar';
import Contents from './components/Contents/Contents';
import Login from './components/Login/Login';
import './App.css';


function App() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <Routes>
        {/* Public Route */}
        <Route path="/Login" element={user ? <Navigate to="/" /> : <Login />} />
      </Routes>

      {!user
        ?
        <Navigate to={"/Login"} />
        :

        <div className="App">
          <Header />
          <div className='MainSetting'>

            <div className='SideBar'>
              <SideBar />
            </div>

            <div className='Content'>
              <Contents />
            </div>

          </div>
        </div>
      }

    </div>
  );
}

export default App;
