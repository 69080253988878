import { useState, useEffect } from 'react';
import { errorNotifier, successNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function usePatients() {
    const [isLoading, setIsLoading] = useState(true);
    const [patientData, setPatientData] = useState([]);
    const [totalAppointments, setTotalAppointments] = useState();
    const [error, setError] = useState(null);

    const fetchPatients = async (id,dateFrom, dateTo) => {
        if (!dateFrom || !dateTo) return; // Exit early if values are not defined
        try {
            setIsLoading(true);
            const {data, status} = await request(`/opd/getTodayVisitForDoc?doctorId=${id}&dateFrom=${dateFrom}&dateTo=${dateTo}`, "GET");
            if (data) {
                setPatientData(data.patients);
                setTotalAppointments(data.totalAppointments);
            } else {
                throw new Error("Invalid response format");
            }
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    return { isLoading,totalAppointments, patientData, error, fetchPatients };
}