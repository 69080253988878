import React from 'react'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import usePatients from '../../hooks/usePatients';
// Components
import Cards from '../Cards/Cards';
import PatientList from '../PatientList/PatientList';

// CSS
import './Home.css';
export default function Home() {
  const { totalAppointments, fetchPatients } = usePatients();

  return (
    <div className='HomeSpliter'>
      <ToastContainer />
      <div className='HomeContainer'>
        <Cards totalAppointments={totalAppointments}/>
        <hr />
      <PatientList/>
      </div>
    </div>
  );
}