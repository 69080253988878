import { useState, useEffect } from 'react';
import { errorNotifier, successNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';

export default function useAppointmentList() {
    const [isLoading, setIsLoading] = useState(true);
    const [appointmentData, setAppointmentData] = useState([]);
    const [error, setError] = useState(null);

    const fetchAppointments =async (id,dateFrom, dateTo) => {
        if (!dateFrom || !dateTo) return;
        try {
            setIsLoading(true);
            const response = await request(`/appointment/getAppointments?doctorId=${id}&dateFrom=${dateFrom}&dateTo=${dateTo}`, "GET");
            const { data, status } = response;
            setAppointmentData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };



    return { isLoading, appointmentData, error,  fetchAppointments };
}
