import React, { useEffect } from 'react';
import useDocumentId from '../../hooks/useDocumentId';
import { useForm } from 'react-hook-form';
import { BsExclamationCircle } from "react-icons/bs";
import { request } from '../../util/fetchAPI';
import { errorNotifier, successNotifier } from '../../util/notifications';

export default function Assessment({id}) {
    const {docIdData, fetchdocIdData } = useDocumentId({});
    const { register, setValue, handleSubmit, watch, reset,formState: { errors } }= useForm();

    const errorMessages = () => (
        <span className='flex items-center justify-start text-red-700 m-1'>
            Required Field <BsExclamationCircle className='m-1 text-lg' />
        </span>
    );

    const PatientFormHandler = async (data) => {

        const payload = {
            accountId:docIdData.accountId,
            prefix:docIdData.prefix,
            visitNo:docIdData.currentId,
            patientId:id,
            ...data
          };

        try {
            const {data, status} = await request('/assessment/addAssessment', 'POST', { 'Content-Type': 'application/json' },
                {...payload});
                if (status === 200) {
                    successNotifier("Case Created")
                  } else {
                    errorNotifier(data.message || "Failed to book visit");
                  }
        } catch (error) {
            console.error('Error adding patient:', error);
            alert('An error occurred while adding the patient');
        }
    };


    useEffect(() => {
        fetchdocIdData("case");

      }, []);

      useEffect(() => {
        if (docIdData?.prefix && docIdData?.currentId) {
          setValue("caseNo", `${docIdData.prefix}`+  -  + `${docIdData.currentId}`);
        }
      }, [docIdData, setValue]);

    return (
        <div className='FormContainer'>
            <div className='Heading'>Medical History</div>
            <div className='FormWrapper'>
                <form onSubmit={handleSubmit(PatientFormHandler)} className='forms'>
                    <div className='InputBox'>
                        <div>
                        <label htmlFor="caseNo">Case No.#</label>
                        <input type="text" {...register("caseNo", { required: true })} className={errors.caseNo ? 'errorInput' : ""} />
                        {errors.caseNo && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="medicalCondition">Medical Condition</label>
                        <input type="text" {...register("medicalCondition", { required: true })} className={errors.medicalCondition ? 'errorInput' : ""} />
                        {errors.medicalCondition && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="surgicalHistory">Surgical History</label>
                        <input type="text" {...register("surgicalHistory", { required: false })} className={errors.surgicalHistory ? 'errorInput' : ""} />
                        {errors.surgicalHistory && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="familyHistory">Family History</label>
                        <input type="text" {...register("familyHistory", { required: false })} className={errors.familyHistory ? 'errorInput' : ""} />
                        {errors.familyHistory && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="medication">Medication</label>
                        <input type="text" {...register("medication", { required: false })} className={errors.medication ? 'errorInput' : ""} />
                        {errors.medication && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="allergies">Allergies</label>
                        <input type="text" {...register("allergies", { required: false })} className={errors.allergies ? 'errorInput' : ""} />
                        {errors.allergies && errorMessages()}
                        </div>

                    </div>

                    <div className='Heading'>
                        Chife Complaint
                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="primaryComplaint">Primary Complaint</label>
                        <input type="text" {...register("primaryComplaint", { required: true })} className={errors.primaryComplaint ? 'errorInput' : ""} />
                        {errors.primaryComplaint && errorMessages()}
                        </div>
                        <div>
                        <label htmlFor="duration">Duration</label>
                        <input type="text" {...register("duration", { required: false })} className={errors.duration ? 'errorInput' : ""} />
                        {errors.duration && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="onset">Onset</label>
                        <input type="text" {...register("onset", { required: false })} className={errors.onset ? 'errorInput' : ""} />
                        {errors.onset && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="aggravatingFactor">Aggravating Factor</label>
                        <input type="text" {...register("aggravatingFactor", { required: false })} className={errors.aggravatingFactor ? 'errorInput' : ""} />
                        {errors.aggravatingFactor && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="relievingFactor">Relieving Factor</label>
                        <input type="text" {...register("relievingFactor", { required: false })} className={errors.relievingFactor ? 'errorInput' : ""} />
                        {errors.relievingFactor && errorMessages()}
                        </div>

                    </div>
                    <div className='Heading'>
                        Physical Examinations
                    </div>
                    <div className='InputBox'>
                        <div><label htmlFor="posture">Posture</label>
                        <input type="text" {...register("posture", { required: false })} className={errors.posture ? 'errorInput' : ""} />
                        {errors.posture && errorMessages()}</div>

                        <div>
                        <label htmlFor="rangeOfMotion">Range of Motion</label>
                        <input type="text" {...register("rangeOfMotion", { required: false })} className={errors.rangeOfMotion ? 'errorInput' : ""} />
                        {errors.rangeOfMotion && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div> <label htmlFor="muscleStrength">Muscle Strength</label>
                        <input type="text" {...register("muscleStrength", { required: false })} className={errors.muscleStrength ? 'errorInput' : ""} />
                        {errors.muscleStrength && errorMessages()}</div>

                        <div>
                        <label htmlFor="reflexes">Reflexes</label>
                        <input type="text" {...register("reflexes", { required: false })} className={errors.reflexes ? 'errorInput' : ""} />
                        {errors.reflexes && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="sensation">Sensation</label>
                        <input type="text" {...register("sensation", { required: false })} className={errors.sensation ? 'errorInput' : ""} />
                        {errors.sensation && errorMessages()}
                        </div>
                    </div>

                    <div className='Heading'>
                        Functional Assessments
                    </div>


                    <div className='InputBox'>
                        <div><label htmlFor="activitiesOfDailyLiving">Activities of Daily Living</label>
                        <input type="text" {...register("activitiesOfDailyLiving", { required: false })} className={errors.activitiesOfDailyLiving ? 'errorInput' : ""} />
                        {errors.activitiesOfDailyLiving && errorMessages()}</div>
                        <div>
                        <label htmlFor="mobility">Mobility</label>
                        <input type="text" {...register("mobility", { required: false })} className={errors.mobility ? 'errorInput' : ""} />
                        {errors.mobility && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="balanceAndCoordination">Balance and Coordination</label>
                        <input type="text" {...register("balanceAndCoordination", { required: false })} className={errors.balanceAndCoordination ? 'errorInput' : ""} />
                        {errors.balanceAndCoordination && errorMessages()}
                        </div>
                        <div>
                        <label htmlFor="cognitiveFunctions">Cognitive Functions</label>
                        <input type="text" {...register("cognitiveFunctions", { required: false })} className={errors.cognitiveFunctions ? 'errorInput' : ""} />
                        {errors.cognitiveFunctions && errorMessages()}
                        </div>

                    </div>

                    <div className='Heading'>
                        Special Test
                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="orthopedicTests">Orthopedic Tests</label>
                        <input type="text" {...register("orthopedicTests", { required: false })} className={errors.orthopedicTests ? 'errorInput' : ""} />
                        {errors.orthopedicTests && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="neurologicalTests">Neurological Tests</label>
                        <input type="text" {...register("neurologicalTests", { required: false })} className={errors.neurologicalTests ? 'errorInput' : ""} />
                        {errors.neurologicalTests && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="otherRelevantTests">Other Relevant Tests</label>
                        <input type="text" {...register("otherRelevantTests", { required: false })} className={errors.otherRelevantTests ? 'errorInput' : ""} />
                        {errors.otherRelevantTests && errorMessages()}
                        </div>

                    </div>

                    <div className='Heading'>
                        Treatment Goals
                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="shortTermGoals">Short Term Goals</label>
                        <input type="text" {...register("shortTermGoals", { required: false })} className={errors.shortTermGoals ? 'errorInput' : ""} />
                        {errors.shortTermGoals && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="shortTermGoals">Long Term Goals</label>
                        <input type="text" {...register("longTermGoals", { required: false })} className={errors.shortTermGoals ? 'errorInput' : ""} />
                        {errors.shortTermGoals && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="patientExpectations">Patient Expectations</label>
                        <input type="text" {...register("patientExpectations", { required: false })} className={errors.patientExpectations ? 'errorInput' : ""} />
                        {errors.patientExpectations && errorMessages()}
                        </div>

                    </div>

                    <div className='Heading'>
                    Physiotherapy Plans
                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="treatmentDuration">Treatment Duration</label>
                        <input type="text" {...register("treatmentDuration", { required: false })} className={errors.treatmentDuration ? 'errorInput' : ""} />
                        {errors.treatmentDuration && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="interventions">Interventions</label>
                        <input type="text" {...register("interventions", { required: false })} className={errors.interventions ? 'errorInput' : ""} />
                        {errors.interventions && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="exercises">Exercises</label>
                        <input type="text" {...register("exercises", { required: false })} className={errors.exercises ? 'errorInput' : ""} />
                        {errors.exercises && errorMessages()}
                        </div>

                        <div>
                        <label htmlFor="electrotherapy">Electrotherapy</label>
                        <input type="text" {...register("electrotherapy", { required: false })} className={errors.electrotherapy ? 'errorInput' : ""} />
                        {errors.electrotherapy && errorMessages()}
                        </div>

                    </div>

                    <div className='InputBox'>
                        <div>
                        <label htmlFor="outcomeMeasures">Outcome Measures</label>
                        <input type="text" {...register("outcomeMeasures", { required: false })} className={errors.outcomeMeasures ? 'errorInput' : ""} />
                        {errors.outcomeMeasures && errorMessages()}
                        </div>

                    </div>

                    <div className='Button'>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    );
}
