import React, { useEffect, useState } from 'react';
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBBreadcrumb,
  MDBBreadcrumbItem,
  MDBListGroup,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import { useParams } from 'react-router-dom';
import { request } from '../../util/fetchAPI';
import profile from "../../assets/profile.png"
import profileGirl from "../../assets/profileGirl.jpg"
import lastVisit from "../../assets/lastVisit.png"
import VisitHistory from '../VisitHistory/VisitHistory';
import PaymentHistory from '../PaymentHistory/PaymentHistory';
import Assesment from '../AssesmentForm/Assesment';
import CaseHistory from '../CaseHistory/CaseHistory';

export default function ProfilePage() {
  const { id } = useParams();
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [basicActive, setBasicActive] = useState('tab1');

  const handleBasicClick = (value) => {
    if (value === basicActive) {
      return;
    }
    setBasicActive(value);
  };

  const fetchPatient = async () => {
    setLoading(true); // Start loading
    try {
      const response = await request(`/opd/getPatient/${id}`, "GET");
      setPatientData(response.data);
    } catch (error) {
      console.error("Error fetching patient data:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchPatient();
  }, [id]);

  return (
    <div>
    {loading ?
      <div className='loaderContainer'>
        <div className="loader"></div>
      </div>
    :
    <section style={{ backgroundColor: '#eee' }}>
      <MDBContainer className="py-5">
        <MDBRow>
          <MDBCol>
            <MDBBreadcrumb className="bg-light rounded-3 p-3 mb-4">
              <MDBBreadcrumbItem>
                Patient
              </MDBBreadcrumbItem>
              <MDBBreadcrumbItem>Patient Profile</MDBBreadcrumbItem>
              <MDBBreadcrumbItem active>Visit-{123}</MDBBreadcrumbItem>
            </MDBBreadcrumb>
          </MDBCol>
        </MDBRow>



        <MDBRow>
          <MDBCol lg="4">
            <MDBCard className=" mb-4 text-center">
              <MDBCardBody className='flex justify-center'>
                <MDBCardImage
                  src={patientData?.patientId?.gender === "Male" ?
                    profile:profileGirl}
                  alt="avatar"
                  className="rounded-circle"
                  style={{ width: '150px' }}
                  fluid />
              </MDBCardBody>
            </MDBCard>

            <MDBCard className="mb-4 mb-lg-0">
              <MDBCardBody className="p-0">
                <MDBListGroup flush className="rounded-3">
                  <div className="flex justify-around p-3 items-center">
                    <div >
                    <img src={lastVisit} alt="" className='h-15 w-10'/>
                    </div>
                    <div >
                      Last Visit On
                    </div>
                    <div className='font-se'>
                      {new Date().toLocaleDateString()}
                    </div>
                  </div>
                </MDBListGroup>
              </MDBCardBody>
            </MDBCard>
          </MDBCol>

          <MDBCol lg="8">
            <MDBCard className="mb-4">
              <MDBCardBody>

                <React.Fragment >
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Patient Id</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.patientId}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Patient Name</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.patientName}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Gender</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.gender}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Age</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.age} Years
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Occupation</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.occupation}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Contact</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.contact}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Address</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {patientData?.patientId?.address}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                  <MDBRow>
                    <MDBCol sm="3">
                      <MDBCardText>Registration</MDBCardText>
                    </MDBCol>
                    <MDBCol sm="9">
                      <MDBCardText className="text-muted">
                        {new Date(patientData?.patientId?.createdAt).toLocaleDateString('en-GB', {
                          day: '2-digit',
                          month: '2-digit',
                          year: 'numeric',
                        })}
                      </MDBCardText>
                    </MDBCol>
                  </MDBRow>
                  <hr />
                </React.Fragment>

              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>

      <>
        <MDBTabs className='mb-3 felx justify-center bg-white'>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab1')} active={basicActive === 'tab1'}>
              Assesment
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab2')} active={basicActive === 'tab2'}>
            Cases
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab3')} active={basicActive === 'tab3'}>
              Visit History
            </MDBTabsLink>
          </MDBTabsItem>
          <MDBTabsItem>
            <MDBTabsLink onClick={() => handleBasicClick('tab4')} active={basicActive === 'tab4'}>
              Payament History
            </MDBTabsLink>
          </MDBTabsItem>
        </MDBTabs>

        <MDBTabsContent className='bg-white p-2'>
          <MDBTabsPane open={basicActive === 'tab1'}><Assesment id={id}/></MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab2'}><CaseHistory id={id}/></MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab3'}><VisitHistory id={id}/></MDBTabsPane>
          <MDBTabsPane open={basicActive === 'tab4'}><PaymentHistory id={id}/></MDBTabsPane>
        </MDBTabsContent>
      </>
    </section>}
    </div>

  );
}
