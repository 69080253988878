import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI'; // Ensure this import is correct and the request function is properly defined in fetchAPI.js

export default function useOPDVisit() {
    const [isLoading, setIsLoading] = useState(true);
    const [visitData, setVisitData] = useState([]);
    const [error, setError] = useState(null);

    const fetchVisit = async () => {
        try {
            setIsLoading(true);
            const response = await request(`/opd/getTodayVisit`, "GET");
            const { data } = response;
            setVisitData(data);
        } catch (error) {
            console.log(error);
            setError(error);
            errorNotifier("Unexpected Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchVisit();
    }, []);

    return { isLoading, visitData, error, refetch: fetchVisit };
}
