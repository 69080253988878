import React from 'react'
import { Route, Routes} from 'react-router-dom';
import Home from '../Home/Home';
import OPDVisitList from '../OPDVisitList/OPDVisitList';
import AppointmentList from '../AppointmentList/AppointmentList';
import TreatmentsList from '../TreatmentsList/TreatmentsList';
import PatientView from '../PatientView/PatientView';
import ReceiptView from '../ReceiptView/ReceiptView';
import ChangePwd from '../ChangePwd/ChangePwd';

export default function AppRoutes() {
  return (
    <div>
     <Routes>
      <Route path='/' element={<Home/>}/>
      <Route path='/changePwd' element={<ChangePwd/>}/>
      <Route path='/patientview/:id' element={<PatientView/>}/>
      <Route path='/appointmentlist' element={<AppointmentList/>}/>
      <Route path='/opdVisitList' element={<OPDVisitList/>}/>
      <Route path='/opdView/:id' element={<ReceiptView/>}/>
      <Route path='/ReceiptView/:id' element={<ReceiptView/>}/>
      <Route path='/treatmentslist' element={<TreatmentsList/>}/>
      </Routes>
    </div>
  )
}
