import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { BsExclamationCircle } from "react-icons/bs";
import useReceiptDtlById from "../../hooks/useReceiptDtlById";
import "./ReceiptView.css";

export default function ReceiptView() {
  const { isLoading, receiptData, error, fetchReceipts, updateReceipt } = useReceiptDtlById();
  console.log(receiptData)
  const { id } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  // Fetch receipt data on component mount or id change
  useEffect(() => {
    fetchReceipts(id);
  }, [id]);

  // Extract the first object from receiptData array
  const receipt = Array.isArray(receiptData) && receiptData.length > 0 ? receiptData[0] : {};

  // Populate form fields when receipt changes
  useEffect(() => {
    if (receipt) {
      setValue("receiptId", receipt?.receiptNo || "");
      setValue("source", receipt?.receiptSource); // Adjust as needed
      setValue("reference",`${receipt?.patientData?.prefix}-${receipt?.patientData?.patientId}`); // Adjust as needed
      setValue("status", receipt?.bankId || "unverified");
      setValue("amount", receipt?.amount || "");
      setValue("receiptType", receipt?.receiptType || "");
      setValue(
        "patientId",
        `${receipt?.patientData?.prefix}-${receipt?.patientData?.patientId}` || ""
      );
      setValue("patientName", receipt?.patientData?.patientName || "");
      setValue("contact", receipt?.patientData?.contact || "");
      setValue("address", receipt?.patientData?.address || "");

      // Correct the doctor and specialization field mappings
      setValue("doctor", `${receipt?.doctorData?.employeeName} (${receipt?.doctorData?.prefix} - ${receipt?.doctorData?.employeeId})` || "");
      setValue("specialization", receipt?.doctorData?.specialization || "");
      setValue("employeeId", receipt?.doctorData?.employeeId || "");
      setValue("prefix", receipt?.doctorData?.prefix || "");
      setValue("employeeName", receipt?.doctorData?.employeeName || "");
    }
  }, [receipt, setValue]);


  const errorMessages = () => (
    <span className="error">
      <BsExclamationCircle /> This field is required
    </span>
  );

  const PatientFormHandler = async (formData) => {
    setIsSubmitting(true); // Start loading
    try {
      await updateReceipt(id, formData); // Call the update API
    } catch (err) {
      console.error("Error updating patient data:", err);
      alert("Failed to update patient data.");
    } finally {
      setIsSubmitting(false); // Stop loading
    }
  };

  if (error) {
    return <div className="error">Error fetching receipt data: {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="FormContainer">
      <div className="Heading">Edit Patient Details</div>

      <div className="FormWraper">
        <form onSubmit={handleSubmit(PatientFormHandler)} className="forms min-h-[80vh] mb-1">
          {/* Receipt ID and Source */}
          <div className="InputBox">
            <div>
              <label htmlFor="receiptId">Receipt ID</label>
              <input
                type="text"
                id="receiptId"
                {...register("receiptId")}
                readOnly
              />
              {errors.receiptId && errorMessages()}
            </div>

            <div>
              <label htmlFor="source">Source</label>
              <input
                type="text"
                id="source"
                {...register("source", { required: true })}
                className={errors.source ? "errorInput" : ""}
              />
              {errors.source && errorMessages()}
            </div>
          </div>

          {/* Reference and Amount */}
          <div className="InputBox">
            <div>
              <label htmlFor="reference">Reference</label>
              <input
                type="text"
                id="reference"
                {...register("reference", { required: true })}
                className={errors.reference ? "errorInput" : ""}
              />
              {errors.reference && errorMessages()}
            </div>

            <div>
              <label htmlFor="reference">Status</label>
              <input
                type="text"
                id="status"
                {...register("status", { required: true })}
                className={errors.status ? "errorInput" : ""}
              />
              {errors.status && errorMessages()}
            </div>
          </div>
          <div className="InputBox">
          <div>
              <label htmlFor="amount">Type</label>
              <input
                type="text"
                id="receiptType"
                {...register("receiptType", { required: true })}
                className={errors.receiptType ? "errorInput" : ""}
              />
              {errors.receiptType && errorMessages()}
            </div>


            <div>
              <label htmlFor="amount">Amount</label>
              <input
                type="text"
                id="amount"
                {...register("amount", { required: true })}
                className={errors.amount ? "errorInput" : ""}
              />
              {errors.amount && errorMessages()}
            </div>
          </div>
          <hr className='border-2 border-gray-400 m-5' />
          {/* Patient ID and Patient Name */}
          <div className="InputBox">
            <div>
              <label htmlFor="patientId">Patient ID</label>
              <input
                type="text"
                id="patientId"
                {...register("patientId")}
                readOnly
              />
              {errors.patientId && errorMessages()}
            </div>

            <div>
              <label htmlFor="patientName">Patient Name</label>
              <input
                type="text"
                id="patientName"
                {...register("patientName", { required: true })}
                className={errors.patientName ? "errorInput" : ""}
              />
              {errors.patientName && errorMessages()}
            </div>
          </div>

          {/* Phone Number and Address */}
          <div className="InputBox">
            <div>
              <label htmlFor="contact">Phone Number</label>
              <input
                type="text"
                id="contact"
                {...register("contact", { required: true })}
                className={errors.contact ? "errorInput" : ""}
              />
              {errors.contact && errorMessages()}
            </div>

            <div>
              <label htmlFor="address">Address</label>
              <input
                type="text"
                id="address"
                {...register("address", { required: true })}
                className={errors.address ? "errorInput" : ""}
              />
              {errors.address && errorMessages()}
            </div>
          </div>

          <hr className='border-2 border-gray-400 m-5' />

          {/* Doctor and Account */}
          <div className="InputBox">
            <div>
              <label htmlFor="doctor">Doctor</label>
              <input
                type="text"
                id="doctor"
                {...register("doctor", { required: true })}
                className={errors.doctor ? "errorInput" : ""}
              />
              {errors.doctor && errorMessages()}
            </div>

            <div>
              <label htmlFor="specialization">Specialization</label>
              <input
                type="text"
                id="specialization"
                {...register("specialization", { required: true })}
                className={errors.specialization ? "errorInput" : ""}
              />
              {errors.specialization && errorMessages()}
            </div>
          </div>

          {/* Submit Button */}

        </form>
      </div>
    </div>
  );
}
