import { useState, useEffect } from 'react';
import { errorNotifier } from '../util/notifications';
import { request } from '../util/fetchAPI';
import React from 'react'

export default function useCaseById() {
    const [isLoading, setIsLoading] = useState(true);
    const [caseData, setCaseData] = useState([]);
    const [error, setError] = useState(null);

    const fetchCase = async (id) => {
        try {
            setIsLoading(true);
            const {data, status} = await request(`/assessment/getPatientCases/${id}`, "GET");
            console.log(data)
            setCaseData(data || []);
        } catch (error) {
            console.log(error);
            setError(error);
           /*  errorNotifier("Unexpected Error"); */
        } finally {
            setIsLoading(false);
        }
    };


    return { isLoading, caseData, error, fetchCase };
}